<template>
    <b-modal 
        content-class="valorem-modal"
        :id="id" 
       
        :cancel-title="tituloCancelar || 'Fechar'"
        :ok-title="tituloOk || $t('GERAL.SALVAR')"
        :ok-disabled="okDisabilitado"
        :ok-only="somenteOk"
        :size="tamanho"

        @ok="emitirOk"
        @cancel="emitirCancelar"
    >
        <template #modal-title>
            <slot name="modal-title">{{tituloModal}}</slot>
        </template>
        
        <slot></slot>
    </b-modal>
</template>
<script>
export default {
    props: {
        id: {
            type : String,
            default : () =>  {return `modal-${Math.random()}`; },
        },
        tituloCancelar: {
            type : String,
            default : null
        },
        tituloOk: {
            type : String,
            default : null
        },
        tituloModal: {
            type : String,
            default : "Modal"
        },
        okDisabilitado : {
            type : Boolean,
            default : false
        },
        somenteOk : {
            type : Boolean,
            default : false
        },
        tamanho : {
            type : String,
            default : "full"
        },

    },
    methods : {
        abrirModal : function(){
            this.$bvModal.show(this.id);
        },
        fecharModal : function(){
            this.$nextTick(() => {
                this.$bvModal.hide(this.id);
            });
        },
        emitirOk : function(e){
            e.preventDefault();
            
            this.$emit('ok');
        },
        emitirCancelar : function(){            
            this.$emit('cancelar');
        }
    }
}
</script>
<style lang="">
    
</style>