import AutenticacaoService from "@/common/services/autenticacao/autenticacao.service";
import JwtService from "@/common/utils/jwt";
import SacadoService from "@/common/services/sacado/sacado.service";

export const VERIFICAR_AUTENTICACAO = "verificarAunteticacao";
export const VERIFICAR_AUTENTICACAO_SACADO = "verificarAunteticacaoSacado";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const LOGOUT_SACADO = "logoutSacado";
export const LIMPAR_DADOS_AUTENTICACAO = "limparDadosAutenticacao";

export const SALVAR_DADOS_AUTENTICACAO = "salvarDadosAuntenticacao";
export const SALVAR_DADOS_AUTENTICACAO_SACADO = "salvarDadosAuntenticacaoSacado";

const state = {
  erros: null,
  user: {},
  estaLogado: !!JwtService.buscarToken(),
};

const getters = {
  usuarioLogado(state) {
    return state.usuario;
  },
  estaLogado(state) {
    return state.estaLogado;
  },
  primeiroLogin(state) {
    return state.primeiroLogin;
  },
};

const actions = {
  async [LOGIN](context, form) {
    return await AutenticacaoService.logar(form).then(async (result) => {
      context.commit(SALVAR_DADOS_AUTENTICACAO, result.data.data);
      return result.data.data;
    });
  },
  async [LOGOUT](context) {
    const _token = JwtService.buscarToken();
    return await AutenticacaoService.invalidarToken(_token).then(async (result) => {
      if (result.data.success) {
        context.commit(LIMPAR_DADOS_AUTENTICACAO);
      }
    });
  },
  async [LOGOUT_SACADO](context) {
    const _token = JwtService.buscarToken();
    return await SacadoService.invalidarToken(_token).then(async (result) => {
      if (result.data.success) {
        context.commit(LIMPAR_DADOS_AUTENTICACAO);
      }
    });
  },
  async [VERIFICAR_AUTENTICACAO](context) {
    const _token = JwtService.buscarToken();
    if (_token === null || _token === undefined) {
      context.commit(LIMPAR_DADOS_AUTENTICACAO);
      return;
    }
    return await AutenticacaoService.validarToken(_token)
      .then(async (result) => {
        if (!result.data.data.valido) {
          context.commit(LIMPAR_DADOS_AUTENTICACAO);
        }
        let claims = result.data.data.claims;
        localStorage.setItem("claims", JSON.stringify(claims));
      })
      .catch(() => {
        //if (!err.response) context.commit(LIMPAR_DADOS_AUTENTICACAO);
        //if (err.response.status == 401) context.commit(LIMPAR_DADOS_AUTENTICACAO);
        context.commit(LIMPAR_DADOS_AUTENTICACAO);
      });
  },
  async [VERIFICAR_AUTENTICACAO_SACADO](context) {


    const _id = localStorage.getItem('token_id');
    if (_id === null || _id === undefined) {
      context.commit(LIMPAR_DADOS_AUTENTICACAO);
      return;
    }
    return await SacadoService.obterToken(_id)
      .then(async (result) => {
        if (!result) {
          return context.commit(LIMPAR_DADOS_AUTENTICACAO);
        }
        if (!result.data.data.accessToken) {
          return context.commit(LIMPAR_DADOS_AUTENTICACAO);
        }

        JwtService.salvarToken(result.data.data.accessToken);
        let parsedJwt = JwtService.parseJwt(JwtService.buscarToken());

        JwtService.salvarNome(parsedJwt.Nome);
        JwtService.salvarTempo(parsedJwt.exp * 1000);
        state.estaLogado = true;
      })
      .catch((err) => {
        console.log("err", err)
        // if (!err.response) context.commit(LIMPAR_DADOS_AUTENTICACAO);
        if (err.response.status == 400) context.commit(LIMPAR_DADOS_AUTENTICACAO);
      });
  },
};

const mutations = {
  [SALVAR_DADOS_AUTENTICACAO](state, usuario) {
    state.estaLogado = true;
    state.usuario = usuario;
    state.errors = {};
    if (usuario.usuario.comercial) JwtService.salvarComercial(usuario.usuario.comercial);
    if (usuario.accessToken) JwtService.salvarToken(usuario.accessToken);
    if (usuario.usuario.nomeUsuario) JwtService.salvarNome(usuario.usuario.nomeUsuario);
    if (usuario.usuario.email) JwtService.salvarEmail(usuario.usuario.email);
    if (usuario.usuario.id) JwtService.salvarId(usuario.usuario.id);
    if (usuario.usuario.codigoAgente) JwtService.salvarCodigoAgente(usuario.usuario.codigoAgente);
    if (usuario.precisaAlterarSenha) {
      state.primeiroLogin = usuario.precisaAlterarSenha;
      JwtService.salvarPrimeiroLogin(usuario.precisaAlterarSenha)
    }
  },
  [LIMPAR_DADOS_AUTENTICACAO](state) {
    const nextURL = window.location.origin + '/login';
    window.location.href = nextURL;
    window.location.assign(nextURL);
    window.location.replace(nextURL);
    state.estaLogado = false;
    state.usuario = {};
    state.errors = {};
    state.primeiroLogin = false;
    JwtService.excluirComercial();
    JwtService.excluirCodigoAgente();
    JwtService.excluirToken();
    JwtService.excluirNome();
    JwtService.excluirSobrenome();
    JwtService.excluirEmail();
    //JwtService.excluirPrimeiroLogin();
    JwtService.excluirId();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
