export const Usuarios = {
  ID: "ID",
  NOME: "Nome",
  SOBRENOME: "Sobrenome",
  USUARIO: "Usuário",
  CPF_CNPJ: "CPF / CNPJ",
  CNPJ: "CNPJ",
  RG: "RG",
  EMAIL: "E-mail",
  TELEFONE: "Telefone",
  TELEFONE_CELULAR: "Telefone/Celular",
  DATA_NASCIMENTO: "Data de Nascimento",
  ENDERECO: "Endereço",
  CONFIRMAR_EXCLUIR: "Tem certeza que deseja excluir o usuário {nome}?",
  MENSAGEM_EXCLUIDO: "Usuário excluído com sucesso.",
  MENSAGEM_CADASTRADO: "Usuário cadastrado com sucesso",
  DADOS_PESSOAIS: "Dados Pessoais",
  LOGRADOURO: "Logradouro",
  NUMERO: "Número",
  CEP: "Cep",
  COMPLEMENTO: "Complemento",
  BAIRRO: "Bairro",
  CIDADE_NOME: "Cidade",
  ESTADO_NOME: "Estado",
  DADOS_ACESSO: "Dados de Acesso",
  CELULAR: "Celular",
  NOME_EMPRESA: "Empresa",
  STATUS: "Status",
  COMERCIAL: "Comercial",
  CODIGOAGENTE: "Código Agente",

  TITULO_SALVAR_USUARIO: "Salvar dados do usuário",
  TITULO_SALVAR_PERFIL: "Salvar dados do perfil",
  TITULO_SENHA: "Alterar senha do usuário",
  INATIVAR_SUCESSO: "Usuário(s) inativado(s) com sucesso.",
  ATIVAR_SUCESSO: "Usuário(s) ativado(s) com sucesso.",
  TITULO_INATIVAR: "Inativar usuário(s)",
  TITULO_ATIVAR: "Ativar usuário(s)",
  CONFIRMACAO_INATIVAR: "Deseja inativar usuário(s) selecionado(s)?",
  CONFIRMACAO_ATIVAR: "Deseja ativar usuário(s) selecionado(s)?",
  BOTAO_INATIVAR: "Inativar usuário(s)",
  BOTAO_ATIVAR: "Ativar usuário(s)",
  BOTAO_ATIVAR_INATIVAR: "Ativar/Inativar usuário(s)",
  EMAIL_ENVIADO_ESQUECEU_SENHA: "E-mail para redefinição de senha enviado com sucesso!",
  INFORMACOES_INCORRETAS: "As informações inseridas não estão corretas, por favor verifique e tente novamente.",
  INFORMACOES_INCORRETAS_COMERCIAL: "Você está tentando acessar uma conta comercial, verifique as informações inseridas e tente novamente.",
};
