export const RotuloBoletos = {
  DATA: "Data",
  ACOES: "Ações",
  NOME_ROTULO: "Nome rótulo",
  CODIGO_ROTULO: "Código rótulo",
  EH_BOLETO: "Esse rótulo é boleto?",
  TITULO_LISTA: "Rótulos",
  TITULO_FORMULARIO_NOVO: "Novo rótulo",
  TITULO_FORMULARIO_EDICAO: "Editar rótulo",
  TITULO_SALVAR : "Deseja salvar o rótulo?",
  TITULO_EXCLUIR : "Deseja apagar o rótulo?",
  MENSAGEM_SUCESSO : "Rótulo salvo com sucesso",
  TITULO_NOVO_ROTULO: "Novo Rótulo",
  CRIAR_ROTULO: "Criar Rótulo",
  TABELA: {
    CODIGO: "Código rótulo",
    NOME: "Nome rótulo"
  }

}