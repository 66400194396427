export const Usuarios = {
  ID: "ID",
  NOME: "Name",
  SOBRENOME: "Last Name",
  CPF_CNPJ: "CPF / CNPJ",
  CPF: "CPF",
  CNPJ: "CNPJ",
  RG: "RG",
  EMAIL: "Email",
  TELEFONE: "Phone Number",
  DATA_NASCIMENTO: "Birth Date",
  ENDERECO: "Address",
  CONFIRMAR_EXCLUIR: "Are you sure you want to delete the user {nome}?",
  MENSAGEM_EXCLUIDO: "User deleted successfully.",
  MENSAGEM_CADASTRADO: "User successfully registered ",
  DADOS_PESSOAIS: "Personal Data",
  LOGRADOURO: "Address",
  NUMERO: "Number",
  CEP: "Zip Code",
  COMPLEMENTO: "Complement",
  BAIRRO: "Neighborhood",
  CIDADE_NOME: "City",
  ESTADO_NOME: "State",
  DADOS_ACESSO: "Access Data",
  STATUS : "Status"
};
