// mutation types
export const DEFINIR_IDIOMA = "definirIdioma";
export const ALTERAR_IDIOMA = "alterarIdioma";

export default {
  state: {
    idioma: window.localStorage.getItem("idioma") ?? "br",
  },
  getters: {
    idioma(state) {
      return state.idioma;
    },
  },
  actions: {
    [DEFINIR_IDIOMA](state, payload) {
      state.commit(ALTERAR_IDIOMA, payload);
      window.localStorage.setItem("idioma", payload);
    },
  },
  mutations: {
    [ALTERAR_IDIOMA](state, payload) {
      state.idioma = payload;
    },
  },
};
