export const RelatorioCedente = {
  OPERACOES: {
    EMPRESA: "Empresa",
    FILIAL: "Filial",
    ADITIVO: "Aditivo",
    SITUACAO: "Situação",
    VALOR_BRUTO: "Valor bruto",
    VALOR_LIQUIDO: "Valor Líquido",
    BORDERO: "Bordero",
    SEQ_ADITIVO: "Seq. aditivo",
  },
  ABERTOS_LIQUIDADOS: {
    CNPJ: "CNPJ",
    CODIGO_EMPRESA: "Empresa",
    CODIGO_FILIAL: "Filial",
    AGENCIA: "Agência",
    CONTA: "Conta",
    BANCO: "Banco",
    DATA_DE: "Data de",
    DATA_ATE: "Data até",
    EMPRESA: "Empresa",
    RELATORIO_ABERTOS: "Relatório Abertos",
    RELATORIO_LIQUIDADOS: "Relatório Liquidados"
  },
  DATA: "Data",
  ACOES: "Ações",
  EMITIR: "Emitir relatório"
}