import store from "@/store/Store";
import { VERIFICAR_AUTENTICACAO_SACADO } from "@/store/autenticacao.module";

export default async function verificaAutenticacaoSacado({ next, router }) {
  let params = decodeURI(window.location.search)
    .replace('?', '')
    .split('&')
    .map(param => param.split('='))
    .reduce((values, [key, value]) => {
      values[key] = value
      return values
    }, {});
  let tokenId = await params.token;

  localStorage.setItem("token_id", tokenId)
  await store.dispatch(VERIFICAR_AUTENTICACAO_SACADO);
  if (!store.getters.estaLogado) {
    return router.push({ name: "Login" });
  }
  return next();
}
