<template>
  <div id="main-wrapper">
    <router-view />
    <loader />
  </div>
</template>

<script>
import Loader from "@/components/loader/Loader";

export default {
  name: "App",
  components: {
    Loader,
  },
};
</script>

<style></style>
