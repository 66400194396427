export const Dashboard = {
  OPERACOES: "Operations",
  OPERACOES_DESC: "Send titles, CNAB and Checks",
  TITULOS: "Titles",
  TITULOS_DESC: "2 way of slips",
  INSTRUCOES: "Instructions",
  INSTRUCOES_DESC: "Sending commands",
  PROGRAMACAO_PAGAMENTOS: "Payment Scheduling",
  PROGRAMACAO_PAGAMENTOS_DESC: "Schedule payments",
  ARQUIVO_RETORNO: "Return File",
  ARQUIVO_RETORNO_DESC: "Download of conciliation",
  REPORTIOS: "Reports",
  REPORTIOS_DESC: "Operation information",
  LIMITE_GLOBAL: "Global Limit",
  A_VENCER: "To Win",
  VENCIDOS: "Overdue",
  PERCENTUAL_LIQUIDACAO: "Settlement Percentage",
  SALDO_CONTA_GRAFICA: "Graphic Account Balance",
  LIMITE_TRANCHE: "Limit Tranche",
};
