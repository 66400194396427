import store from "@/store/Store";
import { VERIFICAR_AUTENTICACAO } from "@/store/autenticacao.module";

export default async function verificaAutenticacao({ next, router }) {
  await store.dispatch(VERIFICAR_AUTENTICACAO);
  
  if (!store.getters.estaLogado) {
    return router.push({ name: "Login" });
  }
  return next();
}
