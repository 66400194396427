<template>
   <div>
    <b-dropdown :id="id" :text="label" :variant="variant">
    <b-dropdown-item
      v-for="action in actions.actions"
      :key="action.id"
    >
    {{action.text}}
    </b-dropdown-item>
  </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "InputDropdown",
  props: {
    value: { type: Boolean },
    actions: {Type: Array},
    label: { type: String },
    variant: { type: String },
    id: { type: String },
  },
  data() {
    return {
     valueCheckbox: this.value,
    };
  },

  methods: {
    onChange() {
      this.$emit('onChange');
      this.valueCheckbox = this.value,
      this.checkDisabled = this.disabled
    }

  },
   watch: {
    value() {
      this.valueCheckbox = this.value
    },
    disabled() {
      this.checkDisabled = this.disabled
    }

  }
};
</script>