import ApiService from "@/common/api/api.service";
import helpers from "../../utils/helpers";

const SacadoService = {
  objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));

    return str.join("&");
  },

  listarBoletos(form) {
    // form.CpfCnpjSacado = form.CpfCnpjSacado ? helpers.removerMascara(form.CpfCnpjSacado) : form.CpfCnpjSacado;
    form.CpfCnpjCedente = form.CpfCnpjCedente
      ? helpers.removerMascara(form.CpfCnpjCedente)
      : "";
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`/AcessoSacado/listar-boletos/?${sendForm}`);
  },
  gerarAcesso(form) {
    form.forceSuccess = true;
    return ApiService.post(`/AcessoSacado`, {
      ...form,
    });
  },
  obterToken(id) {
    return ApiService.get(`/AcessoSacado/obter-jwt-token/?id=${id}`);
  },
  obterBoletos(form) {
    return ApiService.post(`/AcessoSacado/obter-boleto`, form);
  },

  async invalidarToken() {
    let ret = await ApiService.put(
      `AcessoSacado/invalidar-token`,
      {},
      { noToast: true }
    );
    return ret;
  },
};

export default SacadoService;
