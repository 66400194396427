import { Usuarios } from "./usuarios/pt-br";
import { Instrucoes } from "./instrucoes/pt-br";
import { ArquivoRetorno } from "./arquivo-retorno/pt-br";
import { Dashboard } from "./dashboard/pt-br";
import { RelatorioCedente } from "./relatorio_cedente/pt-br";
import { RotuloBoletos } from "./rotulo_boletos/pt-br";
import { Comercial } from "./comercial/pt-br";

export const br = {
  USUARIOS: {
    ...Usuarios,
  },
  INSTRUCOES: {
    ...Instrucoes,
  },
  ARQUIVO_RETORNO: {
    ...ArquivoRetorno,
  },
  DASHBOARD: {
    ...Dashboard,
  },
  RELATORIO_CEDENTE: {
    ...RelatorioCedente,
  },
  ROTULO_BOLETOS: {
    ...RotuloBoletos,
  },
  COMERCIAL: {
    ...Comercial,
  },
  TITULO: "Portal Base Lyncas",
  GERAL: {
    SALVAR: "Salvar",
    SUCCESSO: "Sucesso",
    CANCELAR: "Cancelar",
    ERRO: "Erro",
    EMAIL: "E-mail",
    LOGIN: "Login",
    SENHA: "Senha",
    ACOES: "Ações",
    EDITAR: "Editar",
    VER: "Ver",
    EXCLUIR: "Apagar",
    NOVO: "Novo",
    HOMOLOG: "Homologação",
    DUPLICAR: "Duplicar",
    ATENCAO: "ATENÇÃO",
    EDITAR_TODOS: "Editar Todos",
    SIM: "Sim",
    NAO: "Não",
    MENSAGEM_CAMPO_VAZIO:
      "Por favor preencha todos os campos que estão em vermelho!",
    CONFIRMAR: "Confirmar",
    MENSAGEM_CONFIRMAR_ACAO: "Tem certeza que deseja executar esta ação?",
    UM_ARQUIVO: "Envie apenas um arquivo.",
    PORTUGUES: "Português",
    BUSCAR_INFO_DOCUMENTO: "Buscar informações com este Documento",
    INGLES: "English",
    SAIR: "Sair",
    CADASTRAR: "Cadastrar",
    ADICIONAR: "Adicionar",
    CONFIRMACAO_SENHA: "Confirmação de Senha",
    OK: "Ok",
    SENHA_ATUAL: "Senha Atual",
    TROCAR_SENHA: "Trocar Senha",
    NOVA_SENHA: "Nova Senha",
    CONFIRMAR_NOVA_SENHA: "Confirmar Nova Senha",
    SENHA_ALTERADA: "Senha Alterada com Sucesso",
    ESQUECEU_SENHA: "Esqueceu sua senha?",
    EMAIL_ENVIADO: "E-mail enviado com sucesso!",
    REDEFINIR_SENHA: "Redefinir senha",
    VOLTAR_LOGIN: "Voltar ao Login",
    USUARIO: "Usuário",
    ENTRAR: "Entrar",
    SAIBA_MAIS: "Saiba mais",
    PAGINACAO: "Mostrar todos",
    POR_PAGINA: "Mostrar",
    PAGINA_PRINCIPAL_TITULO: "Receba à vista pelo que você vendeu a prazo!",
    PAGINA_PRINCIPAL_MSG:
      "A antecipação de recebíveis é uma escolha inteligente e\neficaz porque você usa um dinheiro que já pertence à sua empresa",
    MINHA_CONTA: "Minha Conta",
    PERFIL: "Perfil",
    FILTRAR: "Filtrar",
    LIMPAR: "Limpar",
    PESQUISAR: "Pesquisar",
    ACESSO_CEDENTE: "Acesso Cedente",
    ACESSO_SACADO: "Acesso Sacado",
    SUBTITULO_ACESSO_SACADO: "Efetue seu cadastro para ter acesso a segunda via de boleto",
    ACESSO_COMERCIAL: "Acesso Comercial"
  },
  MENU: {
    DASHBOARD: "Dashboard",
    USUARIOS: "Usuários",
    BOLETOS: "Boletos",
    TITULOS: "Títulos",
    PROGRAMACAO: "Programação",
    RELATORIO: "Relatório",
    PERMISSOES: "Permissões",
    OPERACOES: "Operações",
    CONFIGURACOES: "Configurações",
    AUDITORIA: "Auditoria",
    FAQ: "Perguntas Frequentes",
    PERFIL: "Perfil",
    COMUNICADO: "Comunicado",
    INSTRUCOES: "Instruções",
    ARQUIVO_RETORNO: "Arquivo de Retorno",
    ROTULO_BOLETOS: "Rótulos",
    VISITAS_PROSPECCAO: "Visitas de Prospecção",
    VISITAS_MANUTENCAO: "Visitas de Relacionamento",
    CADASTRO_CLIENTES: "Cadastro Clientes Valorem",
    DESEMPENHO_COMERCIAL: "Desempenho comercial",
    CADASTRO_LEAD: "Cadastro de Lead",
    BITRIX: "Bitrix",
    VADU: "Vadu"
  },
  COMUNICADO: {
    ARRASTE: "Arraste seu Comunicado (295x395)",
    CONFIRMAR_EXCLUIR: "Tem certeza que deseja excluir o comunicado {nome}?",
    ARRASTE_CNPJ: "Arraste a lista de CNPJ",
    CRIAR_COMUNICADO: "Criar Comunicado",
    EDITAR_COMUNICADO: "Editar Comunicado",
    DE: "Data de Início",
    ATE: "Data de Finalização",
    TITULO: "Título",
    NOME: "Nome",
    NOME_EMPRESA: "Nome Empresa",
    STATUS: "Status",
    DESC: "Descrição",
    LINK: "Link do Comunicado",
    CNPJ: "CNPJ",
  },
  AUDITORIA: {
    DATA_ALTERACAO: "Data de Alteração",
    DATA_CRIACAO: "Data de Criação",
    DataPrimeiroLogin: "Data Primeiro Login",
    DataUltimoLogin: "Data Último Login",
    TIPO_AUDITORIA: "Tipo da Auditoria",
    ID_ENTIDADE: "Identificador da Entidade",
    NOME_USUARIO: "Nome do Usuário",
    ENTIDADE: "Entidade",
    DETALHES: "Detalhes",
    DE: "De",
    ATE: "Até",
    TIPO: "Tipo",
    VALOR: "Valor",
    CAMPO: "Campo",
    SELECIONE: "Selecione...",
    ADICIONADO: "Adicionado",
    MODIFICADO: "Modificado",
    DELETADO: "Deletado",
    Id: "Identificador",
    ClaimType: "Tipo",
    ClaimValue: "Valor",
    RoleId: "ID do Perfil",
  },
  SACADO: {
    FORNECEDOR: "Fornecedor",
    TITULO: "Título",
    SACADO: "Sacado",
    CEDENTE: "Cedente",
    DATA_VENCIMENTO: "Dt. Vencimento",
    DATA_LIQUIDACAO: "Dt. Liquidação",
    NOME_FANTASIA: "Empresa",
    VALOR: "Valor",
    STATUS: "Status",
    SITUACAO: "Situação",
    SITUACAO_PROTESTO: "Situação protesto",
    VISUALIZAR_C99: "Recompra",
    VALOR_TOTAL: "Valor Total",
    VALOR_TOTAL_ATUALIZADO: "Valor Total Atualizado",
    VALOR_ATUALIZADO: "Valor Atualizado sacado",
    VALOR_PARA_DEPOSITORIO: "Valor para Depósito",
    VALOR_PARA_DEPOSITORIO_PARCIAL: "Valor para Depósito Parcial",
    VALOR_SELECIONADO: "Títulos selecionados",
    EMPRESA: "Empresa",
    ROTULO: "Rótulo",
    OBSERVACOES: "Observações",
  },
  STATUS: {
    ABERTO: "Aberto",
    REJEITADO: "Rejeitado",
    BAIXADO: "Baixado",
    EXCLUIDO: "Excluido",
    SIMULACAO: "Simulação",
  },
  OPERACOES: {
    TIPO: "Selecione o Tipo de Operação",
    CAMPOS_EDITAR: "Selecione o campo que deseja alterar em massa",
    TIPO_DESC:
      "Para melhor experiência com a ferramenta, escolha como quer realizar a operações",
    ALTERAR_CAMPO: "Alterar Campo",
    TITULOS_NOTAS: "Título",
    TITULOS_NOTAS_DESC: "XML e Digitação",
    IMPORTAR_CNAB: "Importação CNAB",
    IMPORTAR_CNAB_DESC: "Importação Bancária",
    CHEQUES: "Cheques",
    CHEQUES_DESC: "Digitação Manual",
    NOTAS_PROMISSORIAS: "Pedido",
    NOTAS_PROMISSORIAS_DESC: "Digitação Manual",
    OPERACOES_LIMPADAS: "Operações limpadas com sucesso!",
    ESCOLHER_OPERACAO: "Escolha a Operação",
    NOVA_OPERACAO: "Nova Operação",
    TITULOS_DUPLICADOS:
      "Existe Títulos duplicados, por favor verifique antes de enviar.",
    PREENCHER_DADOS: "Preencher Dados",
    CONCLUSAO: "Conclusão",
    ARRASTE: "Arraste seus arquivos",
    FORMATO: "Formato .XML",
    FORMATO_CNAB: "Formato .REM, .TXT",
    FORMATO_CNAB_XML: "Formato .XML, .ZIP, .RAR",
    SELECIONAR: "Selecionar arquivos...",
    TOTAL_FACE: "Total Face",
    TOTAL_LIQUIDO: "Total Liquido",
    totalFace: "Total Face",
    totalLiquido: "Total Liquido",
    LIMPAR_OPERACOES: "Limpar Operações",
    ENVIAR_OPERACOES: "Enviar Operações",
    VALIDAR_DADOS_BANCARIOS: "Validar Dados Bancários",
    SELECIONAR_FAVORECIDO: "Selecionar Favorecido",
    REMOVER: "Remover",
    REMOVER_XMLS: "Remover selecionados",
    CNAB: "CNAB",
    UPLOAD_ARQUIVOS: "Arquivo Enviado com Sucesso",
    ERRO_FIELD: "Verifique se todos os campos foram preenchidos corretamente",
    ARQUIVO_EXCLUIDO_MSG: "Item Excluido com Sucesso",
    ARQUIVO_SALVO_MSG: "Item Criado com Sucesso",
    ARQUIVO_ATUALIZADO_MSG: "Item Editado com Sucesso",
    INFORMACOES_MSG:
      "As informações enviadas não corresponde a um titulo valido, favor verificar os campos em vermelho.",
    ITEM_MSG:
      "É necessario selecionar pelo menos um Item na tabela para executar essa ação",
  },
  PERMISSOES: {
    NOME: "Nome",
    ACESSOS: "Acessos",
    CONFIRMAR_EXCLUIR: "Tem certeza que deseja excluir o perfil {nome}?",
    ACESSO: "Acesso",
    PERMISSAO: "Permissões",
    VISUALIZAR: "Visualizar",
    MENSAGEM_CADASTRADO: "Perfil cadastrado com sucesso!",
    CRIAR: "Criar",
    EDITAR: "Editar",
    EXCLUIR: "Excluir",
    ACESSO_AUTENTICACAO: "Autenticação",
    ACESSO_USUARIO: "Usuário",
    ACESSO_PERFIL: "Perfil",
    ACESSO_AUDITORIA: "Auditoria",
    ACESSO_EMAIL: "E-mail",
    ACESSO_CHEQUES: "Cheques",
    ACESSO_CNAB: "CNAB",
    ACESSO_OPERACAO: "Operações",
    ACESSO_TITULOS: "Títulos",
    ACESSO_FAVORECIDOS: "Favorecidos",
    ACESSO_OPERACOESQPROF: "Operações QPROF",
    ACESSO_CONFIGURACOES: "Configurações",
    ACESSO_FAVORECIDO: "Favorecido",
    ACESSO_PROGRAMACAOPAGAMENTO: "Programação de Pagamento",
    ACESSO_CONTABANCARIA: "Conta Bancaria",
    ACESSO_RELATORIO: "Relatório Valorem",
    ACESSO_FILTROTODOSCEDENTESPROGRAMACAO: "Filtro todos cedentes programação",
    ACESSO_INSTRUCOES: "Instruções",
    ACESSO_ARQUIVORETORNO: "Arquivo retorno",
    ACESSO_COMUNICADO: "Comunicados",
    ACESSO_RELATORIOCEDENTE: "Relatório Cedente",
    ACESSO_VALIDADORCNAB: "Validador CNAB",
    ACESSO_ROTULOBOLETOS: "Rótulo de boletos",
    ACESSO_NOTASPROMISSORIAS: "Notas Promissórias",
    ACESSO_COMERCIAL: "Comercial"
  },
  CAMPOS_TABELA_OPERACOES: {
    BORDDERO: "Borderô",
    DATA_HORA: "Data/Hora",
    DATA: "Data",
    USUARIO: "Usuário",
    STATUS: "Status",
    MENSAGEM_RETORNO: "Mensagem Retorno",
    TOTAL_FACE: "Total Face",
    ACOES: "Ações",
    EDITAR: "Editar",
    EXCLUIR: "Excluir",
  },
  TITULOS: {
    BAIXAR_ARQUIVOS: "Baixar Arquivos",
    SEGUNDA_VIA_BOLETOS: "2ª Via Boletos",
    NESTA_OPERACAO: "Nesta Operação",
    RESUMO_PROGAMACAO: "Resumo da Programação",
    VALOR: "Valor",
    DATA_PAGAMENTO: "Data do Pagamento",
    PROGAMAR_PAGAMENTO: "Programar Pagamento",
    EMPRESA: "Empresa",
    VISUALIZAR: "Visualizar",
    NUMERO: "Número",
    TITULOS_ENVOLVIDOS: "Títulos",
    INFO_BANCARIA: "Informações Bancária",
    CONTA: "Conta",
    LISTAR_PROGAMACOES: "Listar Programações",
    AGENCIA: "Agência",
    CNPJ: "CNPJ",
    INFORMACAO_BANCO: "Dados para Pagamento",
    SEM_INFO_BANCO:
      "Sem informações de pagamento, clique duas vezes na linha para mostrar os dados",
    PIX: 'PIX',
    DATA_VENCIMENTO: "Data de vencimento",
    DATA_LIQUIDACAO: "Data de liquidação",
    LIMITE_TITULOS_SEGUNDA_VIA: "Não exceder o limite de 20 títulos selecionados."
  },
  CAMPOS_TABELA_TITULOS: {
    cnpjEmpresa: "CNPJ da Empresa",
    cpfCnpjCedente: "CNPJ do Cedente",
    cpfCnpjSacado: "CNPJ/CPF do Sacado",
    nomeSacado: "Nome do Sacado",
    numeroTitulo: "Numero do Titulo",
    dataEmissao: "Data da Emissão",
    DATA: "Data",
    dataVencimento: "Data de Vencimento",
    VENCIMENTO: "Vencimento",
    CPF_CNPJ: "CPF/CNPJ",
    TELEFONE: "Telefone",
    EMAIL: "Email",
    valorNominal: "Valor de Face",
    VALOR: "Valor",
    valorDesconto: "Valor Desconto",
    chaveNfe: "Chave NFe",
    cmc7: "CMC7",
    CP: "CP",
    endereco: "Endereço",
    numeroEndereco: "Numero do Endereço",
    bairro: "Bairro",
    cidade: "Cidade",
    uf: "UF",
    cep: "CEP",
    email: "Email",
    ddd: "DDD",
    numeroTelefone: "Numero de Telefone",
    nomeArquivo: "Arquivo",
    enderecoSacado: "Endereço do Sacado",
    telefoneSacado: "Telefone do Sacado",
    numero: "Número Endereço",
    tipo: "Tipo",
  },
  CAMPOS_VALIDACAO: {
    INVALIDO: "O campo {{name}} é inválido",
    REQUERIDO: "Por favor informe o campo {name}",
    TAMANHO: "O campo {{name}} precisa possuir {{length}} caracteres",
    TAMANHO_MINIMO:
      "O campo {{name}} precisa possuir no mínimo {{min}} caracteres",
    TERMOS_CONDICOES: "É necessário aceitar os termos e condições",
    NAO_ENCONTRADO: "O usuário {{name}} não foi encontrado",
    LOGIN_INVALIDO: "Login inválido",
    CAMPO_REQUERIDO: "Campo requerido",
    COMPIMENTO_MINIMO: "Comprimento mínimo do campo de {{length}} caracteres",
    COMPRIMENTO_MAXIMO: "Comprimento máximo do campo de {{length}} caracteres",
    VALOR_MINIMO: "Valor mínimo do campo de é {{min}}",
    VALOR_MAXIMO: "Valor máximo do campo de é {{max}}",
    CAMPO_INVALIDO: "Campo não é válido",
    SENHA_E_CONFIRMACAO: "As senhas digitadas não são iguais",
    DATA_MENOR: "A data não pode ser inferior à data de hoje",
  },
  PLACEHOLDER: {
    EMAIL: "Digite seu e-mail",
    EMAIL_USER: "Digite seu e-mail ou usuário",
    SENHA: "Digite sua senha",
    NOME_USUARIO: "Ex: João da Silva",
    VALOR: "Ex: Operação",
    ID_ENTIDADE: "Ex: XYZ",
    SELECIONE: "Selecione",
    CPF_CNPJ: "Digite seu CPF/CNPJ",
  },
};
