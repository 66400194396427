import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import store from "./store/Store";
import router from "./router/Router";
import "./assets/scss/style.scss";
import App from "./App.vue";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
import VueTheMask from "vue-the-mask";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// i18n
import i18n from "./i18n/i18n.js";
import "vue-hljs/dist/style.css";
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard);
Vue.use(VueFeather);

import { AlertasMixin } from "@/common/mixins/AlertasMixin"
import { FormMixin } from '@/common/mixins/FormMixin';
Vue.mixin(AlertasMixin);
Vue.mixin(FormMixin);

import Modal from "@/components/modal/ModalNovo";
import Tabela from "@/components/tabelas/Tabela";
Vue.component('ValoremModal', Modal);
Vue.component('ValoremTabela', Tabela);

Vue.config.productionTip = false;

import vueHljs from "vue-hljs";
import hljs from "highlight.js";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: false,
});

//use
Vue.use(vueHljs, { hljs });
Vue.use(InstantSearch);
Vue.use(VueTheMask);
new Vue({ store, router, i18n, render: (h) => h(App) }).$mount("#app");

String.prototype.formatUnicorn =
  String.prototype.formatUnicorn ||
  function() {
    "use strict";
    var str = this.toString();
    if (arguments.length) {
      var t = typeof arguments[0];
      var key;
      var args =
        "string" === t || "number" === t
          ? Array.prototype.slice.call(arguments)
          : arguments[0];

      for (key in args) {
        str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
      }
    }

    return str;
  };
