export const Instrucoes = {
  DATA_INICIO: "Data de início",
  DATA_FIM: "Data de Fim",
  CPF_CNPJ: "Sacado",
  ADITIVO: "Aditivo",
  SEU_NUMERO: "Seu Número",
  NOSSO_NUMERO: "Nosso Número",
  TITULO: 'Título',
  FILTRAR: "Filtrar",
  LIMPAR: "Limpar",
  PRORROGACAO: "Prorrogação",
  ABATIMENTO: "Abatimento",
  ENCAMINHAR_PROTESTO: "Protesto",
  SUSTAR_PROTESTO: "Sustação Protesto",
  NAO_ENCAMINHAR_CARTORIO: "Não Enviar Cartório",
  PEDIDO_BAIXA: "Baixas (Registro)",
  TITULOS: "Títulos",
  SOLICITADOS: "Solicitados",
  TIPO_INSTRUCAO: "Tipo Instrução",
  TIPO_STATUS: "Tipo Status",
  SALVAR: "Salvar",
  VOLTAR: "Voltar",
  CODIGO_EMPRESA: "Código da Empresa",
  CODIGO_FILIAL: "Código da Filial",
  SEQUENCIAL_TITULO: "Título",
  VALOR: "Valor",
  MOTIVO: "Motivo",
  DATA: "Data",
  LISTAR_INSTRUCOES: "Listar Instruções",
};
