<template>
   <div>
    <b-form-checkbox
      :id="id"
      v-model="valueCheckbox"
      :name="id"
      :key="id"
      v-on:change="onChange()"
      :disabled='checkDisabled'
    >
      {{textCheckbox}}
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: "InputCheckbox",
  props: {
    text: { type: [String, Number], default: "" },
    value: { type: Boolean },
    disabled: { type: Boolean },
    id: { type: String },
  },
  data() {
    return {
     textCheckbox: this.text,
     valueCheckbox: this.value,
     checkDisabled: this.disabled
    };
  },

  methods: {
    onChange() {
      this.$emit('onChange');
      this.valueCheckbox = this.value,
      this.checkDisabled = this.disabled
    }

  },
   watch: {
    value() {
      this.valueCheckbox = this.value
    },
    disabled() {
      this.checkDisabled = this.disabled
    },
    valueCheckbox(){
      this.$emit("input", this.valueCheckbox)
    }

  }
};
</script>