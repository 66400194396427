<template>
   <div>
    <b-form-checkbox
      :id="id"
      v-model="valueCheckbox"
      :name="id"
      :key="id"
      v-on:change="onChange()"
      :disabled='checkDisabled'
      :switch="typeSwitch"
    >
      {{textCheckbox}}
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: "InputCheckbox",
  props: {
    text: { type: [String, Number], default: "" },
    value: { type: Boolean },
    disabled: { type: Boolean },
    id: { type: String },
    switch: { type: Boolean },
  },
  data() {
    return {
     textCheckbox: this.text,
     valueCheckbox: this.value,
     checkDisabled: this.disabled,
     typeSwitch: this.switch
    };
  },

  methods: {
    onChange() {
      this.$emit('onChange');
      this.valueCheckbox = this.value,
      this.checkDisabled = this.disabled
    }

  },
   watch: {
    value() {
      this.valueCheckbox = this.value
    },
    disabled() {
      this.checkDisabled = this.disabled
    },
    switch() {
      this.typeSwitch = this.switch
    },
    valueCheckbox() {
      this.$emit('input', this.valueCheckbox)
    },

  }
};
</script>

<style>
.custom-control-input:focus ~ .custom-control-label::before {
  border-color: var(--primary) !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:active ~ .custom-control-label::before {
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--primary) !important;
}
</style>