export const Instrucoes = {
  DATA_INICIO: "Start Date",
  DATA_FIM: "End Date",
  CPF_CNPJ: "Payee",
  ADITIVO: "Additive",
  SEU_NUMERO: "Your Number",
  NOSSO_NUMERO: "Our Number",
  TITULO: 'Title',
  FILTRAR: "Filter",
  LIMPAR: "Clean",
  PRORROGACAO: "Extension",
  ABATIMENTO: "Rebate",
  ENCAMINHAR_PROTESTO: "Forward Notary",
  SUSTAR_PROTESTO: "Protest",
  NAO_ENCAMINHAR_CARTORIO: "Do Not Forward to Notary",
  PEDIDO_BAIXA: "Write-off Request",
  TITULOS: "Titles",
  SOLICITADOS: "Requested",
  TIPO_INSTRUCAO: "Instruction Type",
  TIPO_STATUS: "Status Type",
  SALVAR: "Save",
  VOLTAR: "Return",
  CODIGO_EMPRESA: "Company Code",
  CODIGO_FILIAL: "Branch Code",
  SEQUENCIAL_TITULO: "Title",
  VALOR: "Value",
  MOTIVO: "Reason",
  DATA: "Date",
  LISTAR_INSTRUCOES: "List Instructions",
};
