<template>
  <div>
    <div :class="emCard ? 'card p-4 mb-3' : ''">
      <slot name="antes-tabela" />
      <b-table
        class="tabela-valorem"
        :class="classes"
        :striped="striped"
        :responsive="responsiva"
        head-variant="light"
        :id="id"
        hover
        :per-page="semPaginacao || backendPaginado ? null : quantidadePorPagina"
        :items="dados"
        :fields="colunas"
        :foot-clone="rodape"
        :bordered="comBorda"
        :no-local-sorting="semOrdenacaoLocal"
        @sort-changed="sortingChanged"
      >
        <template v-for="slot in slots" :slot="slot" slot-scope="dados">
          <div :key="slot">
            <slot v-bind="dados" :name="slot"></slot>
          </div>
        </template>
        <template #cell(seletor)="dados">
          <input-checkbox
            :disabled="!verificarPermissaoSelecao(dados.item)"
            v-model="dados.item.selecionado"
            @onChange="
              () => {
                selecionarIndividual(dados.item, dados.index);
              }
            "
          />
        </template>
        <template #head(seletor)>
          <input-checkbox
            v-if="!semSelecionarTodos"
            :value="todosSelecionado"
            @onChange="
              () => {
                selecionarTodos();
              }
            "
          />
        </template>
      </b-table>
    </div>
    <div class="d-flex align-items-center justify-content-between" v-if="!semPaginacao">
      <div class="d-flex align-items-center">
        <span>Por pagina</span>
        <input-select
          no-label
          :options="opcoesPaginasComputadas"
          v-model="quantidadePorPagina"
          class="paginas-tabela-valorem ml-2"
        ></input-select>
      </div>
      <b-pagination
        v-model="paginaAtual"
        :total-rows="backendPaginado ? quantidadeItens : totalLinhas"
        :per-page="quantidadePorPagina"
        :aria-controls="backendPaginado ? null : id"
        hide-goto-end-buttons
        @change="mudarPagina"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import { InputCheckbox, InputSelect } from "../inputs";
import cloneDeep from "lodash.clonedeep";
export default {
  components: {
    InputCheckbox,
    InputSelect,
  },
  props: {
    id: {
      type: String,
      default: "tabela",
    },
    classes: {
      type: String,
      default: ""
    },
    value: {
      default: [],
    },
    colunas: {
      default: [],
    },
    semPaginacao: {
      type: Boolean,
      default: false,
    },
    rodape: {
      type: Boolean,
      default: false,
    },
    backendPaginado: {
      type: Boolean,
      default: false,
    },
    porPagina: {
      type: Number,
      default: 10,
    },
    quantidadeItens: {
      type: Number,
      default: 0,
    },
    semSelecionarTodos: {
      type: Boolean,
      default: false,
    },
    metodoValidacaoSelecionar: {
      type: Function,
      default: () => {
        return true;
      },
    },
    emCard: {
      type: Boolean,
      default: false,
    },
    striped: {
      type: Boolean,
      default: false,
    },
    responsiva: {
      type: Boolean,
      default: true,
    },
    comBorda: {
      type: Boolean,
      default: false,
    },
    semOrdenacaoLocal: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      dados: [],
      todosSelecionado: false,
      opcoesPaginas: [5, 10, 25, 50, 100],
      quantidadePorPagina: null,
      paginaAtual: 1,
    };
  },
  methods: {
    sortingChanged(e){
      this.$emit("sorting-changed", e);
    },
    selecionarIndividual() {
      // this.dados[index].selecionado = this.dados[index].selecionado;

      let todos = this.dados.every((el) => {
        return el.selecionado;
      });

      this.todosSelecionado = todos;

      this.$emit("input", this.dados);
    },
    selecionarTodos() {
      this.todosSelecionado = !this.todosSelecionado;
      this.dados = this.dados.map((el) => {
        el.selecionado = this.todosSelecionado;
        return el;
      });
      this.$emit("input", this.dados);
    },
    mudarPagina(pagina) {
      this.$emit("paginar", pagina - 1, this.quantidadePorPagina);
    },
    limpar() {
      this.paginaAtual = 1;
      this.dados = [];
    },
    verificarPermissaoSelecao(item) {
      return this.metodoValidacaoSelecionar(item);
    },
    limparSelecao() {
      this.todosSelecionado = false;
      this.dados = this.dados.map((el) => {
        el.selecionado = this.todosSelecionado;
        return el;
      });
      this.$emit("input", this.dados);
    },
    atualizarValor: function (valor) {
      this.dados = cloneDeep(valor).map((el) => {
        if (el.selecionado === undefined) el.selecionado = false;

        return el;
      });
    },
  },
  watch: {
    value: {
      handler() {
        this.dados = cloneDeep(this.value).map((el) => {
          if (el.selecionado === undefined) el.selecionado = false;

          return el;
        });
      },
      immediate: true,
      deep: true,
    },
    porPagina: {
      handler() {
        this.quantidadePorPagina = this.porPagina;
      },
      immediate: true,
    },
    quantidadePorPagina() {
      this.$emit("paginar", 0, this.quantidadePorPagina);
    },
  },
  computed: {
    slots: function () {
      let slots = [];
      slots.push(...Object.keys(this.$slots));
      slots.push(...Object.keys(this.$scopedSlots));
      return slots.filter((el) => el != "antes-tabela");
    },
    totalLinhas: function () {
      return this.dados.length;
    },
    opcoesPaginasComputadas: function () {
      if (!this.porPagina) return this.opcoesPaginas;

      if (this.opcoesPaginas.find((el) => el == this.porPagina))
        return this.opcoesPaginas;

      return [this.porPagina, ...this.opcoesPaginas].sort((e1, e2) => {
        return e1 - e2;
      });
    },
  },
};
</script>
<style lang="scss"></style>
