export const ArquivoRetorno = {
  DATA_INICIO: "Data Inicial",
  DATA_FIM: "Data Final",
  FILTRAR: "Filter",
  LIMPAR: "Clean",
  EMPRESA: "Company",
  FILIAL: "Branch",
  NUMERO: "Number",
  DATA_PREPARACAO: "Date Preparation",
  ARQUIVO: "File",
  ARQ_PEN_FIN: "Financial Pending File",
  DOWNLOAD: "Download",
};
