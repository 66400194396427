<template>
    <b-form-group :label="label" :label-for="id">
      <multiselect
        :id="id"
        v-model="valueLocal"
        :options="options"
        placeholder=""
        :selectLabel="removerLabelAdicionarRemover ? '' : 'Clique para adicionar'"
        :deselectLabel="removerLabelAdicionarRemover ? '' : 'Clique para remover'"
        selectedLabel="Selecionado"
        label="text"
        track-by="value"
        :multiple="!unico"
        :taggable="!unico"
        @input="onInput"
        :disabled="disabled"
      />
    </b-form-group>
  </template>
  <script>
  import Multiselect from "vue-multiselect";
  export default {
    components: {
      Multiselect,
    },
    props: {
      options: {
        required: true,
      },
      value: {
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      id: {
        type: String,
        default: () => {
          return `select-tag-${Math.random()}`;
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      unico: {
        type: Boolean,
        default: false,
      },
      removerLabelAdicionarRemover: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      valueLocal: [],
    }),
    methods: {
      onInput: function (value) {
        if (this.unico) {
          this.$emit("input", value ? value.value : null);
        } else {
          this.$emit(
            "input",
            value.map((el) => el.value)
          );
        }
      },
      selecionarValor: function (valor) {
        if (this.unico) {
          this.valueLocal = this.options.find((element) => element.value == valor);
        } else {
          let values = [];
          valor.forEach((el) => {
            values.push(this.options.find((element) => element.value == el));
          });
          this.valueLocal = values;
        }
      },
    },
    watch: {
      value: function (value) {
        this.selecionarValor(value);
      },
      options: function () {
        this.selecionarValor(this.value);
      },
    },
    mounted() {
      this.selecionarValor(this.value);
    },
  };
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  <style>
  .multiselect__tags {
    min-height: 37px !important;
  }
  .multiselect__option--highlight,
  .multiselect__option--highlight:after {
    background: #fff;
    outline: none;
    color: #333;
  }
  
  .multiselect__tag-icon:after {
    color: #333;
  }
  .multiselect__tag-icon:hover {
    color: #000;
  }
  
  .multiselect__option,
  .multiselect__option:after {
    line-height: 0;
    font-size: 0.875rem;
    min-height: auto;
    top: inherit;
  }
  
  .multiselect {
    box-sizing: inherit;
  }
  
  .multiselect__tag {
    background: #f0f0f0;
    color: #333;
  }
  
  .multiselect__content-wrapper {
    width: calc(100%);
  }
  
  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: #f0f0f0;
  }
  
  .multiselect__option--selected {
    font-weight: inherit;
  }
  .multiselect__single {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
  
  .multiselect__option--selected.multiselect__option--highlight {
    background: #f1f1f1;
    color: #333;
  }
  
  .multiselect__option--highlight {
    background: #f1f1f1;
  }
  </style>
  