<template>
  <b-form-group
    :label="label"
    :label-sr-only="noLabel"
    :label-class="{ 'text-required': required }"
  >
    <multiselect
      :class="{ invalid: !state, valid: state }"
      :stateCustom="state"
      :value="value"
      @input="onChange"
      v-bind="$attrs"
    >
    </multiselect>
    <template v-if="!state">
      <span class="error-message">{{ errorMessage }}</span>
    </template>
  </b-form-group>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "InputMultiSelect",
  components: {
    Multiselect,
  },
  props: {
    label: { type: String },
    required: { type: Boolean },
    noLabel: { type: Boolean, default: false },
    value: {},
    onChange: { type: Function },
    stateCustom: { type: Boolean, default: false },
    errorMessage: { type: String, default: "" },
  },
  computed: {
    state() {
      if (!this.value && this.stateCustom && this.required) return false;
      else return true;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect--active {
  z-index: 10000000;
}

.invalid {
  border: 1px solid red;
}

.valid {
  border: 1px solid green;
}
.error-message {
  color: red;
  margin-top: 16px !important;
  font-size: 12px;
}

/* .multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 35px;
  text-align: left;
  color: #35495e;
}

.multiselect__tags {
  min-height: 35px;
  display: block;
  padding: 0;
  border-radius: 1px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 15px;
}

.multiselect__placeholder {
  color: #4f5467;
  display: inline-block;
  margin-bottom: 0px;
  padding-top: 2px;
}

.multiselect__select {
  position: absolute;
  width: 35px;
  height: 38px;
  right: 1px;
  top: 0px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 35px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
} */
</style>
