import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "@/store/Store";

Vue.use(VueI18n);

import { br } from "./pt-br";
import { en } from "./en";

export default new VueI18n({
  locale: store.getters.idioma, // set default locale
  fallbackLocale: store.getters.idioma,
  silentTranslationWarn: true,
  messages: {
    br,
    en,
  },
});
