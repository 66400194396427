import { Usuarios } from "./usuarios/en";
import { Instrucoes } from "./instrucoes/en";
import { ArquivoRetorno } from "./arquivo-retorno/en";
import { Dashboard } from "./dashboard/en";
import { RelatorioCedente } from "./relatorio_cedente/pt-br";
import { RotuloBoletos } from "./rotulo_boletos/pt-br";
import { Comercial } from "./comercial/pt-br";

export const en = {
  USUARIOS: {
    ...Usuarios,
  },
  INSTRUCOES: {
    ...Instrucoes,
  },
  ARQUIVO_RETORNO: {
    ...ArquivoRetorno,
  },
  DASHBOARD: {
    ...Dashboard,
  },
  RELATORIO_CEDENTE: {
    ...RelatorioCedente,
  },
  ROTULO_BOLETOS: {
    ...RotuloBoletos,
  },
  COMERCIAL: {
    ...Comercial,
  },
  TITULO: "Lyncas Base Portal",
  GERAL: {
    SALVAR: "Salvar",
    SUCCESSO: "Success",
    ERRO: "Error",
    EMAIL: "Email",
    LOGIN: "Login",
    SENHA: "Password",
    ACOES: "Actions",
    EDITAR: "Edit",
    EXCLUIR: "Delete",
    SIM: "Yes",
    NAO: "No",
    CONFIRMAR: "Confirm",
    MENSAGEM_CONFIRMAR_ACAO: "Are you sure you want to perform this action?",
    PORTUGUES: "Portuguese",
    INGLES: "English",
    SAIR: "Sign Out",
    CADASTRAR: "Register",
    ADICIONAR: "Add",
    CONFIRMACAO_SENHA: "Password Confirmation",
    OK: "Ok",
    SENHA_ATUAL: "Senha Atual",
    TROCAR_SENHA: "Change Password",
    NOVA_SENHA: "New Password",
    CONFIRMAR_NOVA_SENHA: "Confirm New Password",
    SENHA_ALTERADA: "Password Changed Successfully",
    ESQUECEU_SENHA: "Forgot your password?",
    EMAIL_ENVIADO: "Email successfully sent!",
    REDEFINIR_SENHA: "Redefine password",
    VOLTAR_LOGIN: "Return to Login",
    USUARIO: "User",
    ENTRAR: "Enter",
    SAIBA_MAIS: "know more",
    PAGINACAO: "Show a lot",
    POR_PAGINA: "Por Pagina",
    PAGINA_PRINCIPAL_TITULO: "Get cash for what you sold on installments!",
    PAGINA_PRINCIPAL_MSG:
      "Prepaying receivables is a smart and\neffective choice because you use money that already belongs to your company",
    MINHA_CONTA: "My account",
  },
  MENU: {
    DASHBOARD: "Dashboard",
    USUARIOS: "Users",
    USUARIOS_PERMISSOES: "Users and Permissions",
    OPERACOES: "Operations",
    CONFIGURACOES: "Settings",
    INSTRUCOES: "Instructions",
    ARQUIVO_RETORNO: "Arquivo de Retorno",
  },
  VALIDATION: {
    INVALIDO: "The {{name}} field is invalid",
    REQUERIDO: "Please enter the field {{name}}",
    TAMANHO: "The {{name}} field must be {{length}} characters",
    TAMANHO_MINIMO: "The {{name}} field must be at least {{min}} characters",
    TERMOS_CONDICOES: "Accepting terms & conditions are required",
    NAO_ENCONTRADO: "The requested {{name}} is not found",
    LOGIN_INVALIDO: "The login detail is incorrect",
    CAMPO_REQUERIDO: "Required field",
    COMPIMENTO_MINIMO: "Minimum field length of {{length}} characters",
    COMPRIMENTO_MAXIMO: "Maximum field length of {{length}} characters",
    VALOR_MINIMO: "Minimum value of field is {{min}}",
    VALOR_MAXIMO: "Maximum value of field is {{max}}",
    CAMPO_INVALIDO: "Field is not valid",
    SENHA_E_CONFIRMACAO: "The passwords entered do not match",
    DATA_MENOR: "The date cannot be less than today's date",
  },
  PLACEHOLDER: {
    EMAIL: "Type  your e-mail",
    SENHA: "Type your password",
  },
};
