export const Dashboard = {
  OPERACOES: "Operações",
  OPERACOES_DESC: "Enviar títulos, CNAB e Cheques",
  TITULOS: "Títulos",
  TITULOS_DESC: "2° via de boletos e títulos",
  INSTRUCOES: "Instruções",
  INSTRUCOES_DESC: "Enviar comandos",
  PROGRAMACAO_PAGAMENTOS: "Programação de Pagamentos",
  PROGRAMACAO_PAGAMENTOS_DESC: "Programar pagamentos",
  ARQUIVO_RETORNO: "Arq. Retorno",
  ARQUIVO_RETORNO_DESC: "Download da conciliação",
  A_VENCER: "À Vencer",
  VENCIDOS: "Vencidos",
  VALOR_VOP_PERIODO: 'Valor Operado 6 Meses',
  SALDO_CONTA_GRAFICA: "Debitos Conta Gráfico ",
  SALDO_DISPONIVEL_GLOBAL: "Saldo Global Disponível",
  SALDO_DISPONIVEL_TRANCHE: "Saldo Tranche Disponível",
  LIMITE_GLOBAL: "Limite Global",  
  LIMITE_TRANCHE: "Limite Tranche",
  RELATORIO_CEDENTE : "Relatórios",
  RELATORIO_CEDENTE_DESC : "Emissão de relatórios da operação",
};
