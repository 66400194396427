import TipoRelatorioCedenteOperacoes from "./TipoRelatorioCedenteOperacoes";
import TipoRelatorioCedenteAbertoLiquidado from "./TipoRelatorioCedenteAbertoLiquidado";
import TipoStatusBordero from "./TipoStatusBordero"
import SituacaoProtesto from "./SituacaoProtesto";

export default {
  TipoRelatorioCedenteOperacoes,
  TipoRelatorioCedenteAbertoLiquidado,
  TipoStatusBordero,
  SituacaoProtesto,

  //Buscar enum pelo value
  getEnum(v) {
    let Enum = [
      ...TipoStatusBordero,
      ...SituacaoProtesto,
    ];
    if (v == -1)
      return { value: -1, text: "Total" }
    return Enum.find(el => el.value == v);
  },
}