export const ArquivoRetorno = {
  DATA_INICIO: "Data Inicial",
  DATA_FIM: "Data Final",
  FILTRAR: "Filtrar",
  LIMPAR: "Limpar",
  EMPRESA: "Empresa",
  FILIAL: "Filial",
  NUMERO: "Número",
  DATA_PREPARACAO: "Data Preparação",
  ARQUIVO: "Arquivo",
  ARQ_PEN_FIN: "Arquivo Pendente Financeiro",
  DOWNLOAD: "Download",
};
