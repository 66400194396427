import moment from "moment";
import Enum from "../enum";
//import config from "@/common/config";

const formatarDataBr = (data, hora = false) => {
  let formato = !hora ? "DD/MM/YYYY" : "DD/MM/YYYY HH:MM";
  return moment(data).format(formato);
};

const formatarValor = (value) => {
  value = parseFloat(value);
  value = value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
  return value;
};

const removerValor = (valor) => {
  return valor.replace('R$','').replace(/\./g, '').replace(',', '.').trim();
};

const removerMascara = (str) => {
  if (str == null || str == undefined || str == "") return "";
  return str.replace(/[^\d]+/g, "");
};

const removerMascaraValor = (str) => {
  if (str == null || str == undefined || str == "") return "";
  str = str.replace(/,/g, ".");
  while (str.split(".").length > 2) {
    str = str.replace(".", "");
  }
  return str.replace(/[^\d.]+/g, "");
};

const primeiraLetraMaiuscula = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const validarEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validarCpfCnpj = (cpfCnpj) => {
  cpfCnpj = removerMascara(cpfCnpj);
  return cpfCnpj.length == 11 ? validarCPF(cpfCnpj) : validarCNPJ(cpfCnpj);
};

const validarEstados = (estado) => {
  let valido = false;
  let listaEstados = [
    { nome: "Acre", sigla: "AC" },
    { nome: "Alagoas", sigla: "AL" },
    { nome: "Amapá", sigla: "AP" },
    { nome: "Amazonas", sigla: "AM" },
    { nome: "Bahia", sigla: "BA" },
    { nome: "Ceará", sigla: "CE" },
    { nome: "Distrito Federal", sigla: "DF" },
    { nome: "Espírito Santo", sigla: "ES" },
    { nome: "Goiás", sigla: "GO" },
    { nome: "Maranhão", sigla: "MA" },
    { nome: "Mato Grosso", sigla: "MT" },
    { nome: "Mato Grosso do Sul", sigla: "MS" },
    { nome: "Minas Gerais", sigla: "MG" },
    { nome: "Pará", sigla: "PA" },
    { nome: "Paraíba", sigla: "PB" },
    { nome: "Paraná", sigla: "PR" },
    { nome: "Pernambuco", sigla: "PE" },
    { nome: "Piauí", sigla: "PI" },
    { nome: "Rio de Janeiro", sigla: "RJ" },
    { nome: "Rio Grande do Norte", sigla: "RN" },
    { nome: "Rio Grande do Sul", sigla: "RS" },
    { nome: "Rondônia", sigla: "RO" },
    { nome: "Roraima", sigla: "RR" },
    { nome: "Santa Catarina", sigla: "SC" },
    { nome: "São Paulo", sigla: "SP" },
    { nome: "Sergipe", sigla: "SE" },
    { nome: "Tocantins", sigla: "TO" },
  ];

  for (let i = 0; i < listaEstados.length; i++) {
    if (estado.toUpperCase() == listaEstados[i].sigla) {
      valido = true;
    }
  }
  return valido;
};

const validarCPF = (cpf) => {
  cpf = removerMascara(cpf);
  if (cpf == "") return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(10))) return false;
  return true;
};

const validarCNPJ = (cnpj) => {
  cnpj = removerMascara(cnpj);

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
};

const validarCep = (cep) => {
  cep = removerMascara(cep);
  let objER = /^[0-9]{2}[0-9]{3}[0-9]{3}$/;

  if (cep.length > 0) {
    if (objER.test(cep)) return true;
    else {
      return false;
    }
  } else {
    return false;
  }
};

const maskCnpj = (v) => {
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
  v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
  return v;
};

const maskCpf = (cpf) => {
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return cpf;
};

const validarAcesso = (acesso) => {
  let claims = JSON.parse(localStorage.getItem("claims"));
  claims = claims.sort((a, b) => {
    return a.value.length - b.value.length;
  });
  let acessos = {};

  for (const key in claims) {
    if (Object.hasOwnProperty.call(claims, key)) {
      if (!acessos[claims[key].type]) {
        acessos[claims[key].type] = {
          type: claims[key].type,
          visualizar: false,
          criar: false,
          editar: false,
          deletar: false,
        };
      }

      acessos[claims[key].type].visualizar =
        claims[key].value.indexOf("R") > -1;
      acessos[claims[key].type].criar = claims[key].value.indexOf("C") > -1;
      acessos[claims[key].type].editar = claims[key].value.indexOf("U") > -1;
      acessos[claims[key].type].deletar = claims[key].value.indexOf("D") > -1;
    }
  }

  return acessos[acesso] || {};
};

const transposeJson = (keyT, array, reduce) => {
  if (reduce) return array.reduce((a, v) => ({ ...a, [v]: v }), {});
  let json = {};

  for (const key in array) {
    if (Object.hasOwnProperty.call(array, key)) {
      if (!json[array[key][keyT]]) {
        json[array[key][keyT]] = { ...array[key] };
      }
    }
  }
  return json || {};
};

const formatarTelefone = (v) => {
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  if (v.length >= 10) {
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  }
  v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos

  return v;
};

const formatarCep = (v) => {
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{5})(\d)/, "$1-$2");

  return v;
};

const clonarObjeto = (v) => {
  return Object.assign({}, v);
};

const filterByValue = (array, value) => {
  return array.filter(
    (data) =>
      JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
};

const formatarDataTime = (data) => {
  var m = data;
  var dateString =
    ("0" + m.getDate()).slice(-2) +
    "/" +
    ("0" + (m.getMonth() + 1)).slice(-2) +
    "/" +
    m.getFullYear() +
    " " +
    ("0" + m.getHours()).slice(-2) +
    ":" +
    ("0" + m.getMinutes()).slice(-2) +
    ":" +
    ("0" + m.getSeconds()).slice(-2);

  return dateString;
};

const objectToQueryString = (obj) => {
  var str = [];
  for (var p in obj)
    str.push(encodeURIComponent("ids") + "=" + encodeURIComponent(obj[p]));

  return str.join("&");
};

const serialize = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });

  let str =
    "?" +
    Object.keys(obj)
      .reduce(function (a, k) {
        a.push(k + "=" + encodeURIComponent(obj[k]));
        return a;
      }, [])
      .join("&");
  return str;
};

const getCnpjFromClaim = () => {
  const claims = JSON.parse(localStorage.claims);
  return claims.find((claim) => claim.type === "CNPJ")?.value;
};

const isAdmin = () => {
  const claims = JSON.parse(localStorage.claims);
  return claims.find((claim) => claim.value == "valorem")?.value;
};

export default {
  formatarDataBr,
  formatarDataTime,
  objectToQueryString,
  primeiraLetraMaiuscula,
  removerMascara,
  validarEmail,
  clonarObjeto,
  validarCpfCnpj,
  transposeJson,
  validarCPF,
  filterByValue,
  validarCNPJ,
  validarCep,
  validarAcesso,
  isAdmin,
  maskCnpj,
  maskCpf,
  validarEstados,
  formatarValor,
  formatarTelefone,
  formatarCep,
  serialize,
  removerMascaraValor,
  getCnpjFromClaim,
  removerValor,
  ...Enum
};
