<template>
  <b-form-group :label="label" class="label" :label-class="{'label_legend':true, 'text-required':required}">
    <b-input-group>
      <the-mask
        ref="imputMask"
        v-model="content"
        class="form-control"
        v-bind:class="{ 'is-valid': state, 'is-invalid': state == false, 'input-autenticacao': authStyle }"
        trim
        :placeholder="placeholder"
        masked
        @input="handleInput"
        :mask="mask"
        @change.native="change"
      />
      <slot name="append"></slot>
      <b-form-invalid-feedback>{{ feedback }}</b-form-invalid-feedback>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "InputMask",
  props: {
    value: { type: String || Number, default: "" },
    label: { type: String, required: true },
    inputClass: { type: String },
    placeholder: { type: String },
    authStyle: { type: Boolean},
    mask: { required: true },
    upper: { type: Boolean },
    required: { type: Boolean },
    requiredMessage: { type: String },
    length: { type: Number, default: 0 },
    lengthMessage: { type: String },
    invalidEmailMessage: { type: String },
    validate: { type: Boolean, default: null },
    minLength: { type: Number, default: null },
  },
  data() {
    return {
      content: this.value,
      validForm: false,
      hasError: false,
      errorMessage: "",
    };
  },
  computed: {
    requiredValid() {
      return !(this.required && this.content == "");
    },
    lengthValid() {
      return !(this.length > 0 && this.content?.length != this.length);
    },
    state() {
      if (!this.validForm) return null;
      if (!this.minLengthValid) return false;
      if (!this.requiredValid) return false;
      if (!this.lengthValid) return false;
      if (this.hasError) return false;
      if (this.validate != null) return this.validate;

      return true;
    },
    minLengthValid() {
      return !(this.minLength > 0 && this.content?.length < this.minLength);
    },
    feedback() {
      if (!this.requiredValid)
        return (
          this.requiredMessage ||
          this.$t("CAMPOS_VALIDACAO.REQUERIDO").formatUnicorn({
            name: this.label,
          })
        );
      if (!this.lengthValid)
        return (
          this.lengthMessage ||
          this.$t("CAMPOS_VALIDACAO.TAMANHO").formatUnicorn({
            name: this.label,
            length: this.length,
          })
        );

      if (this.validate != null && !this.validate)
        return (
          this.invalidEmailMessage ||
          this.$t("CAMPOS_VALIDACAO.INVALIDO").formatUnicorn({
            name: this.label,
          })
        );
      if (!this.minLengthValid)
        return (
          this.minLengthMessage ||
          this.$t("CAMPOS_VALIDACAO.COMPIMENTO_MINIMO").formatUnicorn({
            name: this.label,
            length: this.minLength,
          })
        );
      if (this.hasError) return this.errorMessage;
      return "";
    },
  },
  methods: {
    showError(message) {
      this.validForm = true;
      this.hasError = true;
      this.errorMessage = message;
    },
    handleInput() {
      if (this.upper && this.content) this.content = this.content.toUpperCase();
      this.$emit("input", this.content);
      this.hasError = false;
    },
    valid() {
      this.validForm = true;
      return this.state;
    },
    clear() {
      this.content = "";
      this.validForm = false;
      this.$emit("input", this.content);
    },
    change() {
      this.$emit("change", this.content);
    },
  },
  watch: {
    value: {
      handler(value) {
        this.content = value;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.label {
  overflow: hidden;
}

.label_legend {
  white-space: nowrap;
}
</style>
