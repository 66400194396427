import helpers from "../utils/helpers"
export const FormMixin = {
    methods: {
        validarFormulario: function(prefix ="", validacaoEscondida = false) {
            let arValidation = [];

            for (let ref in this.$refs) {
                if(!ref.includes(prefix)) continue;

                if(this.$refs[ref] == undefined) continue;

                //console.log(ref, this.$refs[ref], typeof this.$refs[ref], this.$refs[ref].length);
                if(this.$refs[ref].length == 0 || this.$refs[ref].length == undefined) {
                    try{
                        arValidation.push(this.$refs[ref].valid(validacaoEscondida));
                    } catch(e) {
                        console.log('Campo não encontrado: ', ref);
                    }
                }else{
                    for(let i = 0; i < this.$refs[ref].length; i++){
                        arValidation.push(this.$refs[ref][i].valid(validacaoEscondida));
                    }
                }
            }
           
            return arValidation.filter((elem) => elem == false).length == 0;
        },
        limparFormulario: function(prefix ="") {

            for (let ref in this.$refs) {
                if(!ref.includes(prefix)) continue;

                if(this.$refs[ref] == undefined) continue;

                //console.log(ref, this.$refs[ref], typeof this.$refs[ref], this.$refs[ref].length);
                if(this.$refs[ref].length == 0 || this.$refs[ref].length == undefined) {
                    try{
                        this.$refs[ref].clear();
                    } catch(e) {
                        console.log('Campo não encontrado: ', ref);
                    }
                }else{
                    for(let i = 0; i < this.$refs[ref].length; i++){
                        this.$refs[ref][i].clear();
                    }
                }
            }
           
        },
        retornarVariante : function(situacao){
            return helpers.Situacao.find(el => el.value == situacao);
        },
    },
}