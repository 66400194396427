// mutation types
export const DEFINIR_STEP = "definirStep";
export const ALTERAR_STEP = "alterarStep";

export default {
  state: {
    step: window.localStorage.getItem("step-operacao") ?? 0,
  },
  getters: {
    step(state) {
      return state.step;
    },
  },
  actions: {
    [DEFINIR_STEP](state, payload) {
      state.commit(ALTERAR_STEP, payload);
      window.localStorage.setItem("step-operacao", payload);
    },
  },
  mutations: {
    [ALTERAR_STEP](state, payload) {
      state.step = payload;
    },
  },
};
