<template>
  <b-form-group :id="id" :label="label">
    <b-form-radio-group
      :id="id"
      v-model="valueCheckbox"
      :name="id"
      :key="id"
      v-on:change="onChange()"
      :disabled="checkDisabled"
      :options="options"
    >
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
export default {
  name: "InputRadio",
  props: {
    id: { type: String },
    label: { type: String },
    value: {},
    disabled: { type: Boolean },
    options: { type: Array },
  },
  data() {
    return {
      textCheckbox: this.text,
      valueCheckbox: this.value,
      checkDisabled: this.disabled,
    };
  },

  methods: {
    onChange() {
      this.$emit("onChange", this.valueCheckbox);
      this.valueCheckbox = this.value;
      this.checkDisabled = this.disabled;
    },
  },
  watch: {
    value() {
      this.valueCheckbox = this.value;
    },
    disabled() {
      this.checkDisabled = this.disabled;
    },
    valueCheckbox() {
      this.$emit("input", this.valueCheckbox);
    },
  },
};
</script>
