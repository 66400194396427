<template>
  <div>
    <label :class="{ 'text-required': required }">{{ label }}</label>
    <b-form-datepicker
      :id="'datepicker-dateformat1 ' + id"
      v-model="content"
      @input="onChange"
      :date-format-options="{
        year: 'numeric',
        month: 'numeric',
        day: '2-digit',
      }"
      locale="br"
    ></b-form-datepicker>
  </div>
</template>

<script>
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
  name: "InputDateTime",
  props: {
    value: { type: String, default: new Date() },
    label: { type: String, required: true },
    required: { type: Boolean, default: false },
    requiredMessage: { type: String },
    id: { type: String },
    validate: { type: Boolean, default: null },
    type: { type: String },
  },
  data() {
    return {
      content: this.value,
      mask: "##/##/#### ##:##",
      validForm: false,
      hasError: false,
      errorMessage: "",
    };
  },
  methods: {
    onChange() {
      this.$emit("onChange", this.content);
    },
  },
  computed: {
    requiredValid() {
      return !(
        this.required &&
        (this.value == "" || this.value == null || this.value == "N/A")
      );
    },
    state() {
      if (!this.validForm) return null;

      if (!this.requiredValid) return false;
      if (this.hasError) return false;
      if (this.validate != null) return this.validate;
      return true;
    },
    feedback() {
      if (!this.requiredValid)
        return (
          this.requiredMessage ||
          this.$t("CAMPOS_VALIDACAO.REQUERIDO").formatUnicorn({
            name: this.label,
          })
        );
      if (this.validate != null && !this.validate)
        return (
          this.invalidEmailMessage ||
          this.$t("CAMPOS_VALIDACAO.INVALIDO").formatUnicorn({
            name: this.label,
          })
        );
      if (this.hasError) return this.errorMessage;
      return "";
    },
  },
};
</script>
<style>
.is-valid input {
  border: 1px solid #0bb7af;
}
.is-invalid input {
  border: 1px solid #f64e60;
}
</style>
